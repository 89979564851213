export const UserPermissions = {
    "camera-controllable": "カメラを制御できる",
    experimental: "実験的機能を利用する",
    "graph-editable": "トレンドグラフ値を編集できる",
    "karte-memo-editable": "メモ作成・編集できる",
};

export const AdminPermissions = {
    "invitation-code-issuable": "招待コードを発行できる",
    "tenant-explorer": "テナント一覧を閲覧できる",
    "user-analysis": "ユーザー分析を閲覧できる",
};
