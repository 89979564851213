import awsmobile from "../aws-exports";
import { ObjectUtils } from "../common/object-utils";
import { HttpApi } from "./http-api";

export namespace UserManagementApi {
    /**
     * ユーザー情報
     */
    export interface User {
        /** 作成日時 (ISO 8601) */
        readonly createdAt: Date;
        /** メールアドレス */
        readonly email: string;
        /** 有効フラグ */
        readonly enabled: boolean;
        /** 名前 */
        readonly name: string;
        /** 権限リスト (`*@user`,`*@root` 以外の権限グループ) */
        readonly permissions: readonly string[];
        /** ユーザー ID のようなもの */
        readonly sub: string;
        /** 所属テナントの ID リスト (`*@user` を持っていれば属しています) */
        readonly tenantIds: readonly string[];
        /** 最終更新日時 (ISO 8601) */
        readonly updatedAt: Date;
    }

    /**
     * ユーザーの変更指令
     */
    export interface UpdateUserInput {
        /** メールアドレス */
        readonly email?: string | undefined;
        /** 有効フラグ */
        readonly enabled?: boolean | undefined;
        /** 名前 */
        readonly name?: string | undefined;
        /** 権限リスト (`*@user`,`*@root` 以外の権限グループ) */
        readonly permissions?: readonly string[] | undefined;
        /** 所属テナントの ID リスト (`*@user` を持っていれば属しています) */
        readonly tenantIds?: readonly string[] | undefined;
    }

    /**
     * オプション定義
     */
    export interface Options {
        /**
         * 中止シグナル
         */
        readonly signal?: AbortSignal | undefined;
    }
}

/**
 * ユーザー管理 API
 */
export const UserManagementApi = new (class UserManagementApi extends HttpApi {
    /**
     * アクセス権限のある全ユーザーを取得します。
     *
     * @param options オプション
     * @returns アクセス権限のある全ユーザー
     */
    async *getUsers(
        options?: UserManagementApi.Options,
    ): AsyncGenerator<UserManagementApi.User[]> {
        const signal = options?.signal;
        const parameters = new URLSearchParams();
        do {
            const response = await this.request("/users", {
                headers: { Accept: "application/json; charset=UTF-8" },
                method: "GET",
                parameters,
                signal,
            });
            if (signal?.aborted) {
                return;
            }

            const data: Record<string, unknown> = await response.json();
            if (signal?.aborted) {
                return;
            }
            if (!response.ok) {
                throw new Error(
                    typeof data.message === "string"
                        ? data.message
                        : `${response.status} ${response.statusText}`,
                );
            }

            if (Array.isArray(data.users)) {
                yield data.users
                    .map(convertToUser)
                    .filter(ObjectUtils.isNotNullable);
            }

            if (typeof data.nextPage === "string") {
                parameters.set("nextPage", data.nextPage);
            } else {
                parameters.delete("nextPage");
            }
        } while (parameters.get("nextPage"));
    }

    /**
     * 指定したユーザーの情報を取得します。
     *
     * 該当ユーザーが存在しなかった場合、`undefined`を返します。
     *
     * @param email ユーザーを特定するためのメールアドレス
     * @param options オプション
     * @returns 該当ユーザー
     */
    async getUser(
        email: string,
        options?: UserManagementApi.Options,
    ): Promise<UserManagementApi.User | undefined> {
        if (email.includes("/") || !email.includes("@")) {
            return undefined;
        }

        const response = await this.request(
            `/users/${encodeURIComponent(email)}`,
            {
                headers: { Accept: "application/json; charset=UTF-8" },
                method: "GET",
                signal: options?.signal,
            },
        );
        if (response.status === 404) {
            return undefined;
        }

        const data = await response.json();
        if (!response.ok) {
            throw new Error(
                typeof data.message === "string"
                    ? data.message
                    : `${response.status} ${response.statusText}`,
            );
        }

        return convertToUser(data.user);
    }

    /**
     * 指定したユーザーを削除します。
     *
     * @param email ユーザーを特定するためのメールアドレス
     * @param options オプション
     */
    async deleteUser(
        email: string,
        options?: UserManagementApi.Options,
    ): Promise<void> {
        if (email.includes("/") || !email.includes("@")) {
            throw new Error("Invalid email");
        }

        const response = await this.request(
            `/users/${encodeURIComponent(email)}`,
            {
                headers: { Accept: "application/json; charset=UTF-8" },
                method: "DELETE",
                signal: options?.signal,
            },
        );
        if (response.status === 404) {
            return;
        }

        if (!response.ok) {
            const data = await response.json();
            throw new Error(
                typeof data.message === "string"
                    ? data.message
                    : `${response.status} ${response.statusText}`,
            );
        }
    }

    /**
     * 指定したユーザーの情報を更新します。
     *
     * @param email ユーザーを特定するためのメールアドレス
     * @param input 更新内容
     * @param options オプション
     * @returns 更新後のユーザー情報
     */
    async updateUser(
        email: string,
        input: UserManagementApi.UpdateUserInput,
        options?: UserManagementApi.Options,
    ): Promise<UserManagementApi.User> {
        if (email.includes("/") || !email.includes("@")) {
            throw new Error("Invalid email");
        }

        const response = await this.request(
            `/users/${encodeURIComponent(email)}`,
            {
                body: JSON.stringify(input),
                headers: {
                    Accept: "application/json; charset=UTF-8",
                    "Content-Type": "application/json; charset=UTF-8",
                },
                method: "PATCH",
                signal: options?.signal,
            },
        );

        const data = await response.json();
        if (!response.ok) {
            throw new Error(
                typeof data.message === "string"
                    ? data.message
                    : `${response.status} ${response.statusText}`,
            );
        }

        const resultUser = convertToUser(data.user);
        if (resultUser === undefined) {
            throw new Error("Unknown error");
        }
        return resultUser;
    }
})(awsmobile.serecaUserManagementApiEndpoint);

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

function convertToUser(data: unknown): UserManagementApi.User | undefined {
    if (!ObjectUtils.isObject(data)) {
        return undefined;
    }

    return {
        createdAt: new Date(String(data.createdAt)),
        email: String(data.email),
        enabled: Boolean(data.enabled),
        name: String(data.name),
        permissions: Array.isArray(data.permissions)
            ? data.permissions.map(String)
            : [],
        sub: String(data.sub),
        tenantIds: Array.isArray(data.tenantIds)
            ? data.tenantIds.map(String)
            : [],
        updatedAt: new Date(String(data.updatedAt)),
    };
}
