import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { UserManagementApi } from "../../../../controller/user-management-api";

export namespace useUsers {
    /**
     * {@link useUsers} フックの結果の型
     */
    export interface Result {
        readonly error?: unknown;
        readonly loading: boolean;
        readonly overwrite: (
            sub: string,
            user: UserManagementApi.User | undefined,
        ) => void;
        readonly users: UserMap;
    }

    export type UserMap = ReadonlyMap<
        UserManagementApi.User["sub"],
        UserManagementApi.User
    >;
}

/**
 * 全ユーザーを取得します。
 *
 * 結果の `users` は徐々に増えます。
 * (サーバーから少しずつ取得するので)
 *
 * 取得が完了すると `loading` フラグが `false` になります。
 *
 * @param disabled 無効フラグ
 * @returns 取得状態と結果
 */
export function useUsers(disabled = false): useUsers.Result {
    const [error, setError] = useState<unknown>();
    const [loading, setLoading] = useState(false);
    const [usersPrevious, setUsersPrevious] = useState(EmptyUserMap);
    const [usersCurrent, setUsersCurrent] = useState(EmptyUserMap);
    const loadingRef = useRef(loading);
    loadingRef.current = loading;

    // 上書きする関数を定義する
    const overwrite = useCallback(
        (sub: string, user: UserManagementApi.User | undefined) => {
            setUsersCurrent((prev) => {
                const next = new Map(prev);
                if (user === undefined) {
                    next.delete(sub);
                } else {
                    next.set(sub, user);
                }
                return next;
            });
        },
        [],
    );

    // 結果を構築する
    const result = useMemo<useUsers.Result>(() => {
        let users: useUsers.UserMap;
        if (usersPrevious === EmptyUserMap) {
            users = usersCurrent;
        } else {
            const union = new Map();
            for (const [sub, user] of usersPrevious) {
                union.set(sub, user);
            }
            for (const [sub, user] of usersCurrent) {
                union.set(sub, user);
            }
            users = union;
        }
        return { error, loading, overwrite, users };
    }, [error, loading, overwrite, usersCurrent, usersPrevious]);

    // 取得する
    useEffect(() => {
        if (disabled) {
            setError(undefined);
            setLoading(false);
            setUsersPrevious(EmptyUserMap);
            setUsersCurrent(EmptyUserMap);
            return undefined;
        }

        // 初期化する
        setError(undefined);
        setLoading(true);
        setUsersCurrent((prev) => {
            setUsersPrevious(prev);
            return EmptyUserMap;
        });

        // ユーザーを取得する
        const ac = new AbortController();
        fetchAllUsers(ac.signal, setUsersCurrent)
            .then(() => {
                if (ac.signal.aborted !== true) {
                    setError(undefined);
                    setLoading(false);
                    setUsersPrevious(EmptyUserMap);
                }
            })
            .catch((error: unknown) => {
                if (ac.signal.aborted !== true) {
                    setError(error);
                    setLoading(false);
                    setUsersPrevious(EmptyUserMap);
                }
            });

        return () => {
            ac.abort();
        };
    }, [disabled]);

    return result;
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const EmptyUserMap: useUsers.UserMap = new Map();

/**
 * 全ユーザーを取得します。
 *
 * @param signal 中止シグナル
 * @param setUsers 更新関数
 */
async function fetchAllUsers(
    signal: AbortSignal,
    setUsers: Dispatch<SetStateAction<useUsers.UserMap>>,
): Promise<void> {
    for await (const newUsers of UserManagementApi.getUsers({ signal })) {
        if (signal.aborted) {
            return;
        }
        if (newUsers.length === 0) {
            continue;
        }

        setUsers((prev) => {
            const next = new Map(prev);
            for (const newUser of newUsers) {
                next.set(newUser.sub, newUser);
            }
            return next;
        });
    }
}
